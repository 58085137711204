import ListGroup from "react-bootstrap/ListGroup";

import { Disk } from "../../types";

interface DiskStatusProps {
  disk: Disk;
}

export default function DiskStatus({ disk }: DiskStatusProps) {
  const { used, size, percentage } = disk;
  return (
    <ListGroup.Item>
      <div className="d-flex">
        <strong>Disk</strong>
        <div className="ms-auto">
          {used} / {size} ({percentage} full)
        </div>
      </div>
    </ListGroup.Item>
  );
}
