import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

import Overview from "./Overview";
import Manifest from "./Manifest";

export default function ProcessorManifests() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:processorId`}>
        <div>
          [ <Link to={match.url}>Back</Link> ]
        </div>
        <Manifest />
      </Route>
      <Route path={match.path}>
        <div>
          [ <Link to="/">Home</Link> ]
        </div>
        <Overview />
      </Route>
    </Switch>
  );
}
