import { useContext, useCallback, useMemo } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { DateTime } from "luxon";

import { useCollection } from "../../useFirebase";
import Auth from "../../Auth";
import { Status } from "./types";
import { cellStyle } from "./utils";
import { Column } from "react-table";
import BaseTable from "../../components/BaseTable";
import PeriodicRender from "../../components/PeriodicRender";

interface DisplayStat {
  pending: number;
  running: number;
  errors: number;
}

function displayStat(stat?: Record<string, number>): DisplayStat {
  let running = 0;
  let errors = 0;
  let pending = 0;
  if (!stat) {
    return { running, errors, pending };
  }
  Object.entries(stat).forEach(([key, value]) => {
    if (key === "running") {
      running += value;
      pending += value;
    } else if (
      key === "not-started" ||
      key.startsWith("retry") ||
      key.startsWith("resume")
    ) {
      pending += value;
    } else if (["failed", "disk-full"].includes(key)) {
      errors += value;
      pending += value;
    }
  });
  return { running, errors, pending };
}

// Display the # of pending operations (or blank if zero).
function StatCell({ value: { pending } }: { value: DisplayStat }) {
  return pending > 0 ? pending : "";
}

// Sort the column by # of pending operations (same as value in cell).
function statSort(rowA: any, rowB: any, columnId: string) {
  const a = rowA.values[columnId].pending;
  const b = rowB.values[columnId].pending;
  if (a > b) {
    return 1;
  } else if (b > a) {
    return -1;
  }
  return 0;
}

export default function ManifestStats() {
  const { user } = useContext(Auth);
  const isAdmin = user?.admin;
  const match = useRouteMatch();
  const urlBase = match.url;
  const status = useCollection<Status>("processor-status", {
    operators: user!.admin ? undefined : user!.operators,
  });

  const columns: Column<Status>[] = useMemo(() => {
    return [
      {
        Header: "Hostname",
        accessor: "id",
        Cell: ({ value }) => <Link to={`${urlBase}/${value}`}>{value}</Link>,
      },
      {
        Header: "Last update",
        id: "updateTimestamp",
        accessor: ({ timestamp }) => timestamp.toDate(),
        Cell: ({ value }: { value: Date }) => (
          <PeriodicRender>
            {() => {
              const datetime = DateTime.fromJSDate(value);
              const stale = datetime.plus({ minutes: 15 }) < DateTime.now();
              const color = stale ? "red" : "inherit";
              return <span style={{ color }}>{datetime.toRelative()}</span>;
            }}
          </PeriodicRender>
        ),
        sortType: "datetime",
      },
      {
        Header: "Operator",
        accessor: "operator",
      },
      {
        Header: "Stored",
        id: "stored",
        accessor: ({ manifestStats }) => displayStat(manifestStats?.stored),
        Cell: StatCell,
        sortType: statSort,
      },
      {
        Header: "Raw Upload",
        id: "rawUpload",
        accessor: ({ manifestStats }) => displayStat(manifestStats?.raw_upload),
        Cell: StatCell,
        sortType: statSort,
      },
      {
        Header: "Processed",
        id: "processed",
        accessor: ({ manifestStats }) => displayStat(manifestStats?.processed),
        Cell: StatCell,
        sortType: statSort,
      },
      {
        Header: "Processed Upload",
        id: "processedUpload",
        accessor: ({ manifestStats }) =>
          displayStat(manifestStats?.processed_upload),
        Cell: StatCell,
        sortType: statSort,
      },
      {
        Header: "Swift",
        id: "swiftVersion",
        accessor: ({ versions }) => versions?.swift,
      },
      {
        Header: "Web-UI",
        id: "webVersion",
        accessor: ({ versions }) => versions?.webui,
      },
    ];
  }, [urlBase]);

  const state = {
    hiddenColumns: isAdmin ? [] : ["operator", "swiftVersion", "webVersion"],
  };

  const getCellProps = useCallback((cell) => {
    if (
      ["stored", "rawUpload", "processed", "processedUpload"].includes(
        cell.column.id
      )
    ) {
      const { running, errors } = cell.value as DisplayStat;
      return { style: cellStyle(running, errors) };
    }
    return {};
  }, []);

  return (
    <>
      <Row>
        <Col>
          <h2>Processors</h2>
          <p>
            Shows # of pending operations (if any). Green if running, red if any
            failed.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={9}>
          <BaseTable
            columns={columns}
            data={status}
            initialState={state}
            getCellProps={getCellProps}
            autoResetSortBy={false}
            tableProps={{
              hover: true,
              bordered: true,
              striped: true,
              size: "sm",
              responsive: "md",
              className: "text-nowrap",
              style: { fontSize: "0.8rem" },
            }}
          />
        </Col>
      </Row>
    </>
  );
}
