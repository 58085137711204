import ListGroup from "react-bootstrap/ListGroup";

import { Strobe } from "../../types";
import { strobesOk } from "../../utils";

interface StrobeStatusProps {
  strobes: Record<string, Strobe>;
}

export default function StrobeStatus({ strobes }: StrobeStatusProps) {
  if (strobesOk(strobes)) {
    return null;
  }

  // e.g. "lower-left=2 upper-right=1"
  const errors = Object.entries(strobes)
    .filter(([_, v]) => v.flash_miss_delta !== 0)
    .map(([k, v]) => `${k}=${v.flash_miss_delta}`);
  errors.sort();

  return (
    <ListGroup.Item variant="danger">
      <div className="d-flex">
        <strong className="text-nowrap me-2">Strobe flash miss</strong>
        <div className="ms-auto row">
          {errors.map((error) => (
            <span key={error} className="text-nowrap col">
              {error}
            </span>
          ))}
        </div>
      </div>
    </ListGroup.Item>
  );
}
