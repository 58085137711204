// https://github.com/Microsoft/TypeScript/issues/28502
/// <reference types="resize-observer-browser" />

import axios from "axios";
import React, { useEffect, useContext, useState, useMemo } from "react";
import ReactMapboxGL, { MapContext, Source, Layer } from "react-mapbox-gl";
import mapboxgl, { Style, BackgroundLayer } from "mapbox-gl";
import { Map, Globe } from "react-bootstrap-icons";

import MapControl from "./MapControl";

import "mapbox-gl/dist/mapbox-gl.css";
import googleLogo from "./google.png";

const ReactMap = ReactMapboxGL({
  accessToken: "", // Typescript requires a value.
  attributionControl: true,
  customAttribution: '© <a href="https://greenatlas.com.au">Green Atlas</a>',
  doubleClickZoom: false,
  // @ts-ignore: typings are incorrect.
  hash: "view",
});

const backgroundLayer: BackgroundLayer = {
  id: "background",
  type: "background",
  paint: { "background-color": "#ebe9e4" },
};

const childSeparatorLayer: mapboxgl.BackgroundLayer = {
  id: "child-separator",
  type: "background",
  paint: { "background-color": "transparent" },
};

const BASE_STYLE: Style = {
  version: 8,
  sources: {},
  layers: [backgroundLayer, childSeparatorLayer],
};

function Resizer() {
  // Resize the map whenever it's containing element changes size. When we
  // resize the map, lock the north west corner so that it doesn't appear to
  // shift under the mouse.
  const map = useContext(MapContext);
  useEffect(() => {
    if (ResizeObserver && map) {
      const obs = new ResizeObserver(() => {
        try {
          if (!map.isMoving()) {
            const nw_orig = map.getBounds().getNorthWest();
            map.resize();
            const nw_new = map.getBounds().getNorthWest();
            const center = map.getCenter();
            const newCenter = new mapboxgl.LngLat(
              center.lng + nw_orig.lng - nw_new.lng,
              center.lat + nw_orig.lat - nw_new.lat
            );
            map.setCenter(newCenter);
          } else {
            // If the map is moving, getBounds() won't give us the target bounds, so
            // we don't know where to anchor.
            map.resize();
          }
        } catch (error) {
          // Ignore.
        }
      });
      const container = map.getContainer();
      obs.observe(container);
      // Unobserve when map is changed.
      return () => obs.unobserve(container);
    }
  }, [map]);
  return null;
}

function AttributionFixer() {
  // The attribution button doesn't have 'type="button"' set so it defaults to
  // being a submit button. We don't want it submitting stuff, so fix it.
  const map = useContext(MapContext);
  useEffect(() => {
    if (map) {
      // Override type.
      const btns = map
        .getContainer()
        .getElementsByClassName("mapboxgl-ctrl-attrib-button");
      if (btns.length > 0) {
        (btns[0] as HTMLButtonElement).type = "button";
      }
    }
  }, [map]);
  return null;
}

interface GMapSession {
  session: string;
  expiry: string;
  imageFormat: "jpeg" | "png";
  tileWidth: 256 | 512;
  tileHeight: 256 | 512;
}

async function getToken(apiKey: string): Promise<GMapSession> {
  const resp = await axios.post(
    `https://tile.googleapis.com/v1/createSession?key=${apiKey}`,
    {
      mapType: "satellite",
      language: "en-US",
      region: "US",
    }
  );
  return resp.data;
}

function GoogleSatellite() {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const [session, setSession] = useState<GMapSession | null>(null);

  useEffect(() => {
    if (apiKey) {
      getToken(apiKey)
        .then(setSession)
        .catch(() => {
          console.error("Failed to get Google Maps session token");
        });
    } else {
      console.log("Not using Google Maps satellite layer, no API key provided");
    }
  }, [apiKey]);

  const tileJsonSource = useMemo(() => {
    if (session === null || typeof apiKey !== "string") {
      return null;
    }
    return {
      type: "raster",
      tileSize: session.tileWidth,
      tiles: [
        `https://tile.googleapis.com/v1/2dtiles/{z}/{x}/{y}?session=${session.session}&key=${apiKey}`,
      ],
    };
  }, [session, apiKey]);

  if (tileJsonSource === null) {
    return null;
  }

  return (
    <>
      <Source id="google-satellite" tileJsonSource={tileJsonSource} />
      <Layer
        id="google-satellite"
        type="raster"
        sourceId="google-satellite"
        paint={{
          "raster-opacity": 0.6,
        }}
        before="child-separator"
      />
      <MapControl plain position="bottom-left">
        <img src={googleLogo} alt="Google" />
      </MapControl>
    </>
  );
}

function OpenStreetMap() {
  const tileJsonSource = useMemo(
    () => ({
      type: "raster",
      tileSize: 256,
      tiles: [
        "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
      ],
      maxzoom: 16,
      attribution:
        '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }),
    []
  );

  return (
    <>
      <Source id="open-street-map" tileJsonSource={tileJsonSource} />
      <Layer
        id="open-street-map"
        sourceId="open-street-map"
        type="raster"
        paint={{
          "raster-opacity": 0.6,
        }}
        before="child-separator"
      />
    </>
  );
}

function BackgroundMap() {
  const [gmaps, setGmaps] = useState(false);
  const otherName = gmaps ? "street map" : "satellite view";
  return (
    <>
      <MapControl position="top-right">
        <button
          onClick={() => setGmaps(!gmaps)}
          title={`Switch background to ${otherName}`}
        >
          {gmaps ? <Map /> : <Globe />}
        </button>
      </MapControl>
      {gmaps ? <GoogleSatellite /> : <OpenStreetMap />}
    </>
  );
}

export type FitBounds = [[number, number], [number, number]];

export type BaseMapProps = Omit<React.ComponentProps<typeof ReactMap>, "style">;

export function BaseMap({ children, ...props }: BaseMapProps) {
  return (
    <ReactMap
      style={BASE_STYLE}
      containerStyle={{ width: "100%", height: "100%", minHeight: "400px" }}
      movingMethod="easeTo"
      {...props}
    >
      <>
        <Resizer key="resizer" />,
        <AttributionFixer key="fixer" />
        <BackgroundMap />
        {children}
      </>
    </ReactMap>
  );
}
