import React from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";

import Overview from "./Overview";
import TimeSeries from "./TimeSeries";

export default function Scanners() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:scannerId`}>
        <div>
          [ <Link to={match.url}>Back</Link> ]
        </div>
        <TimeSeries />
      </Route>
      <Route path={match.path}>
        <div>
          [ <Link to="/">Home</Link> ]
        </div>
        <Overview />
      </Route>
    </Switch>
  );
}
