import { useContext } from "react";
import { Link } from "react-router-dom";

import Auth from "../Auth";

const Home = () => {
  const { user } = useContext(Auth);
  return (
    <>
      <h2 className="pt-4">Dashboards</h2>
      <ul>
        {user?.admin && (
          <li>
            <Link to="/log-backups">Log backups</Link>
          </li>
        )}
        <li>
          <Link to="/processor">Processor status</Link>
        </li>
        <li>
          <Link to="/scanners">Scanner status</Link>
        </li>
        {user?.admin && (
          <li>
            <Link to="/calibration">Calibration QA</Link>
          </li>
        )}
      </ul>
    </>
  );
};

export default Home;
