import { useCallback } from "react";
import { Column } from "react-table";

import { CalibrationQA } from "./types";
import { getRelationshipName } from "./utils";
import BaseTable from "../../components/BaseTable";

const COLUMNS: Column<CalibrationQA>[] = [
  {
    Header: "date",
    id: "date",
    accessor: (row: CalibrationQA) =>
      row.serverTimestamp.toDate().toDateString(),
  },
  {
    Header: "operator",
    id: "operator",
    accessor: (row: CalibrationQA) => row.content.operator,
  },
  {
    Header: "processor",
    id: "processor",
    accessor: (row: CalibrationQA) => row.content.hostname,
  },
  {
    Header: "crop",
    id: "crop",
    accessor: (row: CalibrationQA) => row.content.crop,
  },
  {
    Header: "stage",
    id: "stage",
    accessor: (row: CalibrationQA) => row.content.stage,
  },
  {
    Header: "type",
    id: "type",
    accessor: (row: CalibrationQA) =>
      getRelationshipName(
        row.content.relationship.xLabel,
        row.content.relationship.yLabel
      ),
  },
  {
    Header: "gradient",
    id: "gradient",
    accessor: (row: CalibrationQA) =>
      row.content.relationship.gradient.toFixed(2),
  },
  {
    Header: "CV",
    id: "cv",
    accessor: (row: CalibrationQA) => {
      const gradient = row.content.relationship.gradient;
      const gradientStddev = row.content.relationship.gradientStddev;
      if (typeof gradientStddev === "undefined") {
        return "n/a";
      }
      return ((gradientStddev / gradient) * 100).toFixed(1) + " %";
    },
  },
  {
    Header: "modified by",
    id: "user",
    accessor: (row: CalibrationQA) => row.modifiedBy,
  },
];

interface QATableProps {
  items: CalibrationQA[];
  shownId?: string;
  focusId?: string;
  setHoverId: (id?: string) => void;
  setFocusId: (id?: string) => void;
}

export default function QATable({
  items,
  shownId,
  focusId,
  setHoverId,
  setFocusId,
}: QATableProps) {
  const getRowProps = useCallback(
    (row: any) => ({
      onMouseOver: () => setHoverId(row.original.id),
      onMouseLeave: () => setHoverId(undefined),
      onClick: () =>
        focusId === row.original.id
          ? setFocusId(undefined)
          : setFocusId(row.original.id),
      className:
        shownId === row.original.id
          ? "table-success"
          : focusId === row.original.id
          ? "table-light"
          : "",
    }),
    [setHoverId, setFocusId, focusId, shownId]
  );

  if (items.length === 0) {
    return <p>All items resolved</p>;
  }

  return (
    <BaseTable
      columns={COLUMNS}
      data={items}
      getRowProps={getRowProps}
      autoResetSortBy={false}
    />
  );
}
