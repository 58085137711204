import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useCollection } from "../../useFirebase";
import { QAState, CalibrationQA } from "./types";

import QATable from "./QATable";
import QACard from "./QACard";

function useCalibrationQAs(state: QAState) {
  const options = useMemo(
    () => ({
      filter: ["state", "==", state],
      orderBy: ["serverTimestamp", "desc"],
      limit: 100,
    }),
    [state]
  );
  return useCollection<CalibrationQA>("calibration-approval", options);
}

export default function Calibration() {
  const pending = useCalibrationQAs("new");
  const completed = useCalibrationQAs("complete");
  const followUp = useCalibrationQAs("follow-up");

  const [hoverId, setHoverId] = useState<string>();
  const [focusId, setFocusId] = useState<string>();

  const shownId = hoverId ?? focusId;

  const tableProps = {
    focusId,
    shownId,
    setHoverId,
    setFocusId,
  };

  const handleStateChange = () => {
    // When the user hits a button to change the QA state, we hide the card.
    // The next-pending item might then get shown due to the useEffect below.
    setFocusId(undefined);
  };

  useEffect(
    () => {
      if (typeof shownId === "undefined" && pending.length > 0) {
        setFocusId(pending[0].id);
      }
    },
    // Only want this to trigger when the "pending" items change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pending]
  );

  return (
    <>
      <div>
        [ <Link to="/">Home</Link> ]
      </div>
      <h1>Calibration QA</h1>
      <Row className="mt-2">
        <Col xl={{ span: 5, order: "last" }} className="mb-4 sticky-top-pad">
          {shownId && <QACard id={shownId} onStateChange={handleStateChange} />}
        </Col>
        <Col xl={7}>
          <h2>QA required</h2>
          <QATable items={pending} {...tableProps} />
          <h2 className="mt-4">Follow-up required</h2>
          <QATable items={followUp} {...tableProps} />
          <h2 className="mt-4">QA complete</h2>
          <QATable items={completed} {...tableProps} />
        </Col>
      </Row>
    </>
  );
}
