import "react-scrubber/lib/scrubber.css";

import { DateTime } from "luxon";
import { Scrubber } from "react-scrubber";

export interface InstantSelectorProps {
  // The scrubber will allow selection of times between `start` and `end`.
  start: DateTime;
  end: DateTime;
  instant: DateTime | null;
  setInstant: (instant: DateTime | null) => void;
}

// Similar to `TimeRangeSelector`, but selects an instant in time using a scrubber. The
// selected instant is used by `ScannerHistory` to augment the scanner trajectory display.
export default function InstantSelector({
  start,
  end,
  instant,
  setInstant,
}: InstantSelectorProps) {
  const startSec = start.toSeconds();
  const endSec = end.toSeconds();
  // When `instant` is `null`, set scrub all the way to the right.
  const instantSec = instant?.toSeconds() ?? endSec;

  // TODO: react-scrubber supports adding coloured 'markers' to the scrubber (like the
  // ones used by youtube to show ads in the video). We could use these to show periods
  // where the scanner was active, however this requires fetching the scanner history
  // from this component.

  return (
    <div className="d-flex flex-row align-items-center">
      <span className="me-3">
        {instant === null
          ? "---- -- -- --:--"
          : instant.toFormat("yyyy-MM-dd H:mm:ss")}
      </span>
      <span style={{ height: "25px" }} className="my-1 me-2 flex-fill">
        <Scrubber
          min={startSec}
          max={endSec}
          value={instantSec}
          // Scrubber colour is greyed when inactive to provide a visual cue.
          className={instant === null ? "inactive" : "active"}
          // Deactivate the scrubber if value is set to maximum.
          onScrubChange={(value) =>
            setInstant(value === endSec ? null : DateTime.fromSeconds(value))
          }
        />
      </span>
    </div>
  );
}
