import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { DateTime } from "luxon";
import { withErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./ErrorFallback";
import { textForMode, variantForSummary, StatusSummary } from "../../utils";

interface HeaderProps {
  hostname: string;
  timestamp: DateTime;
  summary: StatusSummary;
}

function Header({ hostname, timestamp, summary }: HeaderProps) {
  const variant = variantForSummary(summary, /*useStale*/ true);
  const modeText = textForMode(summary.mode);
  return (
    <ListGroup.Item>
      <div className="d-flex align-baseline" style={{ fontSize: 18 }}>
        <div>{hostname}</div>
        <div className="ms-auto mb-1">
          <Badge bg={variant}>{modeText}</Badge>
        </div>
      </div>
      <div className="mt-n2">{timestamp.toRelative()}</div>
    </ListGroup.Item>
  );
}

export default withErrorBoundary(Header, {
  fallback: <ErrorFallback title="Scanner" />,
});
