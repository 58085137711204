import { useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import ListGroup from "react-bootstrap/ListGroup";
import {
  CaretUpFill as CollapseIcon,
  CaretDownFill as ExpandIcon,
} from "react-bootstrap-icons";

import { Status } from "../../types";
import { displayStatus } from "../../utils";

interface StatusDetailProps {
  status: Status;
}

export default function StatusDetail({ status }: StatusDetailProps) {
  const [show, setShow] = useState(false);

  return (
    <ListGroup.Item>
      <div className="d-flex">
        <div onClick={() => setShow((s) => !s)} className="flex-grow-1">
          <strong>Detailed status</strong>
          {show ? <CollapseIcon /> : <ExpandIcon />}
        </div>
        <div>
          <Link to={`/scanners/${status.hostname}`}>plots</Link>
        </div>
      </div>
      <Collapse in={show}>
        <div>
          <pre style={{ whiteSpace: "pre-wrap" }}>
            {JSON.stringify(displayStatus(status), null, 2)}
          </pre>
        </div>
      </Collapse>
    </ListGroup.Item>
  );
}
