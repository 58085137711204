import Table from "react-bootstrap/Table";
import { ArrowUp, ArrowDown } from "react-bootstrap-icons";
import { useSortBy, useTable, TableOptions } from "react-table";

export default function BaseTable<D extends object>(options: TableOptions<D>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options, useSortBy);

  // `options.get(Row|Cell)Props` can be used to override row properties.
  const getRowProps = options.getRowProps ?? (() => ({}));
  const getCellProps = options.getCellProps ?? (() => ({}));

  return (
    <Table responsive hover size="sm" {...getTableProps(options.tableProps)}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ArrowDown />
                    ) : (
                      <ArrowUp />
                    )
                  ) : (
                    ""
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps(getRowProps(row))}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps([getCellProps(cell)])}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
