import { useEffect } from "react";

import firebase from "../firebase";

export default function Logout() {
  useEffect(() => {
    async function doLogout() {
      // Small delay so user can read message.
      await new Promise((r) => setTimeout(r, 600));
      // Sign out of firebase.
      await firebase.auth().signOut();
      // Sign out of FusionAuth (otherwise would get auto signed-in to firebase).
      window.location.href = "/auth/logout";
    }
    doLogout();
  }, []);

  return <>Logging out...</>;
}
