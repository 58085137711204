import { DateTime } from "luxon";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";

import { Initialisation } from "../../types";
import { Mode } from "../../utils";

interface InitStatusProps {
  mode: Mode;
  init: Initialisation;
}

export default function InitStatus({ init }: InitStatusProps) {
  if (init.status === "complete") {
    return null;
  }

  const numMessages = init.message?.length ?? 0;

  return (
    <ListGroup.Item>
      <div
        className={`d-flex ${init.status === "inactive" ? "bg-warning" : ""}`}
      >
        <strong>Initialisation</strong>
        <div className="ms-auto">{init.status}</div>
      </div>
      {numMessages > 0 && (
        <Table size="sm" style={{ lineHeight: 1 }}>
          <thead>
            <tr>
              <th>message</th>
              <th>time</th>
            </tr>
          </thead>
          <tbody>
            {(init.message ?? []).map((m, index) => (
              <InitMessage message={m} position={numMessages - index} />
            ))}
          </tbody>
        </Table>
      )}
    </ListGroup.Item>
  );
}

interface InitMessageProps {
  message: string;
  position: number;
}

function InitMessage({ message, position }: InitMessageProps) {
  const [timestamp, content] = message.split(/: (.*)/);
  const match = timestamp.match(/\d\d:\d\d:\d\d [AP]M/);
  let timeStr = "";
  if (match) {
    const parsed = DateTime.fromFormat(match[0], "tt", { zone: "UTC" });
    timeStr = parsed
      .setZone("system")
      .toLocaleString(DateTime.TIME_24_WITH_SECONDS);
  }

  return (
    <tr className={position !== 1 ? "text-secondary" : ""}>
      <td>{content}</td>
      <td>{timeStr}</td>
    </tr>
  );
}
