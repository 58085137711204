import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import firebase from "./firebase";

export interface User {
  displayName: string;
  admin: boolean;
  operators: string[];
}

interface ContextProps {
  user: User;
  pending: boolean;
  logout: () => void;
}

const Auth = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const history = useHistory();

  const [firebaseUser, setFirebaseUser] = useState<firebase.User | null>();
  const [user, setUser] = useState<User>();
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    // `onAuthStateChanged` returns its unsubscribe handler.
    return firebase.auth().onAuthStateChanged((user: firebase.User | null) => {
      setFirebaseUser(user);
    });
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      firebaseUser.getIdTokenResult().then((idToken) => {
        const { email, displayName } = firebaseUser;
        const {
          claims: { operators, admin },
        } = idToken;
        const user = {
          displayName: (displayName || email) as string,
          admin: admin,
          operators: operators,
        };
        setUser(user);
        setPending(false);
      });
    } else if (firebaseUser === null) {
      // 'null' means state was loaded, but no user is signed in.
      setPending(false);
      setUser(undefined);
    }
  }, [firebaseUser]);

  const logout = useCallback(() => history.push("/logout"), [history]);

  return (
    <Auth.Provider value={{ user, pending, logout }}>{children}</Auth.Provider>
  );
};

export default Auth;
