import React, { useContext } from "react";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import Auth from "../Auth";
import brandImg from "../banner.png";

const Header = () => {
  const { user, logout } = useContext(Auth);

  return (
    <Navbar
      sticky="top"
      bg="light"
      className="py-1 px-2"
      style={{ height: "4rem" }}
    >
      <LinkContainer to="/" className="me-auto">
        <Navbar.Brand>
          <img
            src={brandImg}
            className="d-inline-block align-top"
            alt="Green Atlas logo"
          />
        </Navbar.Brand>
      </LinkContainer>
      {user && (
        <Button
          size="sm"
          variant="outline-primary"
          onClick={logout}
          title={user.displayName}
        >
          Sign out
        </Button>
      )}
    </Navbar>
  );
};

export default Header;
