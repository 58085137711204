import React from "react";
import { DateTime } from "luxon";

import firebase from "./firebase";

interface Options {
  filter?: any[];
  orderBy?: string[];
  reverse?: boolean;
  limit?: number;
  operators?: string[];
}

export interface Document {
  id: string;
}

export function useCollection<T extends Document>(
  collection: string,
  options: Options = {}
) {
  const { filter, orderBy, reverse, limit, operators } = options;
  const [objects, setObjects] = React.useState<T[]>([]);
  React.useEffect(() => {
    let query: any = firebase.firestore().collection(collection);
    // Limit to documents with one of the given operators.
    // NOTE: This is enforced in `firestore.rules`.
    if (typeof operators !== "undefined" && operators.length > 0) {
      query = query.where("operator", "in", operators);
    }
    if (typeof filter !== "undefined") {
      // Allow filter to contain one or two clauses.
      if (filter.length === 3) {
        query = query.where(...filter.slice(0, 3));
      } else if (filter.length === 6) {
        query = query.where(...filter.slice(0, 3));
        query = query.where(...filter.slice(3, 6));
      } else {
        throw new Error("Invalid query length");
      }
    }
    if (typeof orderBy !== "undefined") {
      query = query.orderBy(...orderBy);
    }
    if (typeof limit !== "undefined") {
      query = query.limit(limit);
    }
    return query.onSnapshot(
      (snapshot: firebase.firestore.QuerySnapshot) => {
        let objects = snapshot.docs.map(
          (doc: any) =>
            ({
              id: doc.id,
              ...doc.data(),
            } as T)
        );
        if (typeof orderBy === "undefined") {
          // If query-level `orderBy` is not provieded, order objects by their
          // id using alpha-numeric sort.
          const collator = new Intl.Collator(undefined, { numeric: true });
          const sorted = objects.sort((a: Document, b: Document) =>
            collator.compare(a.id, b.id)
          );
          setObjects(reverse ? sorted.reverse() : sorted);
        } else {
          setObjects(objects);
        }
      },
      (error: firebase.firestore.FirestoreError) => {
        console.log(error);
      }
    );
  }, [collection, filter, orderBy, reverse, limit, operators]);
  return objects as T[];
}

export function useDocument<T extends Document>(document: string) {
  const [doc, setDoc] = React.useState<T | null>(null);

  React.useEffect(() => {
    return firebase
      .firestore()
      .doc(document)
      .onSnapshot((doc) => {
        setDoc({ id: doc.id, ...doc.data() } as T);
      });
  }, [document]);

  return doc;
}

export function parseTime(t: firebase.firestore.Timestamp): DateTime {
  return DateTime.fromMillis(t.seconds * 1000 + t.nanoseconds / 1e6);
}
