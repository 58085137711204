import React from "react";
import Container from "react-bootstrap/Container";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "./Auth";
import Header from "./components/Header";
import PrivateRoute from "./PrivateRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Processor from "./pages/Processor";
import Scanners from "./pages/Scanners";
import LogBackups from "./pages/LogBackups";
import Calibration from "./pages/Calibration";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <Container
          fluid
          className="bg-white pt-2 d-flex flex-column"
          style={{ minHeight: "calc(100vh - 4rem)" }}
        >
          <Switch>
            {/* PrivateRoutes redirect to "/login" if the user is not logged in. */}
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute
              path="/log-backups"
              requiresAdmin
              component={LogBackups}
            />
            <PrivateRoute path="/processor" component={Processor} />
            <PrivateRoute path="/scanners" component={Scanners} />
            <PrivateRoute
              path="/calibration"
              requiresAdmin
              component={Calibration}
            />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
          </Switch>
        </Container>
      </AuthProvider>
    </Router>
  );
};

export default App;
