import Plotly from "plotly.js-basic-dist";
import { DateTime } from "luxon";

import { Status, Trinket } from "./types";
import { summarizeStatus, colourForMode } from "./utils";

type TrinketField = keyof Exclude<Trinket, "t">;

function trinketData(statusList: Status[], field: TrinketField): number[] {
  return statusList.map((s) =>
    s.trinket ? (s.trinket[field] as number) : NaN
  );
}

function diskData(statusList: Status[]): number[] {
  return statusList.map((s) => (s.disk ? parseInt(s.disk.percentage) : NaN));
}

// Create the `data` object needed to plot the status history.
export function makePlotData(statusList: Status[]): Plotly.Data[] {
  const xs = statusList.map((s) => s.timestamp.toDate());
  const mode = statusList.map((s) => summarizeStatus(s, DateTime.now()).mode);
  const modeColour = mode.map((m) => colourForMode(m));
  const modeLogStr = statusList.map((s) => {
    const mode = summarizeStatus(s, DateTime.now()).mode;
    const logName = s.log?.name;
    return logName ? `${mode} ${logName}` : mode;
  });
  const voltage = trinketData(statusList, "voltage");
  const current = trinketData(statusList, "current_mean");
  const disk = diskData(statusList);

  return [
    {
      x: xs,
      y: Array(statusList.length).fill(1),
      text: modeLogStr,
      yaxis: "y1",
      name: "Status",
      mode: "markers",
      marker: {
        color: modeColour,
        size: 12,
        symbol: 1,
      },
      hoverinfo: "x+text",
    },
    {
      x: xs,
      y: voltage,
      yaxis: "y2",
      name: "Voltage (V)",
    },
    {
      x: xs,
      y: current,
      yaxis: "y3",
      name: "Current (A)",
    },
    {
      x: xs,
      y: disk,
      yaxis: "y4",
      name: "Disk use (%)",
    },
  ];
}

// Create the `layout` object needed to plot the status history.
export function makePlotLayout(
  start: DateTime | null,
  end: DateTime | null
): Partial<Plotly.Layout> {
  const endDate = end ? end.toJSDate() : new Date();

  return {
    height: 700,
    showlegend: false,
    margin: { t: 20, b: 40, l: 30, r: 20 },
    xaxis: {
      rangeslider: {
        range: [start?.toJSDate(), endDate] as [Plotly.Datum, Plotly.Datum],
      },
      type: "date" as Plotly.AxisType,
    },
    yaxis: {
      range: [0.01, 1.5],
      domain: [0.95, 1],
    },
    yaxis2: {
      autorange: true,
      domain: [0.7, 0.9],
    },
    yaxis3: {
      domain: [0.45, 0.65],
    },
    yaxis4: {
      domain: [0.2, 0.4],
    },
    grid: {
      rows: 4,
      columns: 1,
      subplots: ["xy", "xy2", "xy3", "xy4"],
    },
    hovermode: "x",
    annotations: [
      {
        text: "Logging Status",
        y: 1.4,
        showarrow: false,
        yref: "y domain" as Plotly.YAxisName,
        xref: "x domain" as Plotly.XAxisName,
      },
      {
        text: "Battery Voltage",
        y: 1,
        showarrow: false,
        yref: "y2 domain" as Plotly.YAxisName,
        xref: "x domain" as Plotly.XAxisName,
      },
      {
        text: "Battery Current",
        y: 1.1,
        showarrow: false,
        yref: "y3 domain" as Plotly.YAxisName,
        xref: "x domain" as Plotly.XAxisName,
      },
      {
        text: "Disk Usage",
        y: 0.9,
        showarrow: false,
        yref: "y4 domain" as Plotly.YAxisName,
        xref: "x domain" as Plotly.XAxisName,
      },
    ],
  };
}
