import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Auth from "./Auth";

interface PrivateRouteProps extends RouteProps {
  requiresAdmin?: boolean;
  component: any;
}

const PrivateRoute = ({
  component: RouteComponent,
  requiresAdmin = false,
  ...rest
}: PrivateRouteProps) => {
  const { user, pending } = useContext(Auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (pending) {
          return <div>Loading...</div>;
        } else if (typeof user === "undefined") {
          return <Redirect to="/login" />;
        } else if (requiresAdmin && !user.admin) {
          return (
            <p>
              Not authorized. Contact Green Atlas if you believe you should have
              access.
            </p>
          );
        }
        return <RouteComponent {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
