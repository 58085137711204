import { ReactNode, useEffect, useState } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Ensures firebase is initialized before doing anything else.
interface FirebaseLoaderProps {
  children: ReactNode;
}
export function FirebaseLoader({ children }: FirebaseLoaderProps) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    initFirebase().then(() => setReady(true));
  }, []);

  if (ready) {
    return <>{children}</>;
  } else {
    return null;
  }
}

async function initFirebase() {
  if (process.env.REACT_APP_FIREBASE_EMULATORS) {
    // Connect to the local emulators.
    firebase.initializeApp({
      projectId: "greenatlas-dev",
      apiKey: "emulator-api-key",
    });
    console.log("connecting emulators");
    firebase.auth().useEmulator("http://127.0.0.1:9099");
    firebase.firestore().useEmulator("127.0.0.1", 8080);
  } else {
    // Use SDK auto configuration.
    const resp = await fetch("/__/firebase/init.json");
    firebase.initializeApp(await resp.json());
  }
}

export default firebase;
