import { useMemo, useRef } from "react";
import { DateTime } from "luxon";

import firebase from "../../firebase";
import { Status, LogRate, Position, Strobe, Strobes, Log } from "./types";

export function shortId(scannerId: string) {
  return scannerId.replace("scanner-", "");
}

export function parseTime(t: firebase.firestore.Timestamp): DateTime {
  return DateTime.fromMillis(t.seconds * 1000 + t.nanoseconds / 1e6);
}

export function getPosition(
  status: Status,
  chooseBy: "latest" | "northmost"
): [number, number] {
  // Fort Denison ftw.
  let pos: Position = { longitude: 151.225564, latitude: -33.854889 };

  if (status.position && status.position.length > 0) {
    if (chooseBy === "latest") {
      pos = status.position[status.position.length - 1];
    } else if (chooseBy === "northmost") {
      pos = status.position.reduce((prev, curr) =>
        prev.latitude > curr.latitude ? prev : curr
      );
    }
  }
  return [pos.longitude, pos.latitude];
}

// Keep only the status fields that we want to show in a popup.
export function displayStatus(status: Status) {
  return {
    timestamp: parseTime(status.timestamp).toISO(),
    initialisation: status.initialisation,
    log: status.log,
    disk: status.disk,
    strobes: status.strobes,
    trinket: status.trinket,
    configuration: status.configuration,
  };
}

// For the purposes of colouring the map / plots, we reduce the status
// message to one of the following "modes".
export enum Mode {
  INIT_RUNNING = "Initialising",
  INIT_INACTIVE = "Initialisation inactive",
  LOGGING_OK = "Logging",
  LOGGING_ERROR = "Logging (with errors)",
  IDLE = "Idle",
}
// The mode and timestamp of the status message.
export interface StatusSummary {
  mode: Mode;
  // A message is stale if it is either too old or too new with respect to the currently
  // selected time range and instant.
  stale: boolean;
}

// If rate isn't checked, treat it as ok.
function rateOk(rate: LogRate) {
  return rate.above_threshold === "unchecked" || Boolean(rate.above_threshold);
}

export function logRatesOk(log: Log) {
  // Empty rates dict is 'ok'.
  return Object.values(log.rate || {}).reduce(
    (prev, entry: any) => prev && rateOk(entry),
    true
  );
}

export function strobesOk(strobes?: Strobes): Boolean {
  // No strobe status is counted as 'ok'.
  return Object.values(strobes ?? {}).reduce((ok: boolean, strobe: Strobe) => {
    // We only care about the flash_miss_delta value.
    return ok && strobe.flash_miss_delta === 0;
  }, true);
}

export function summarizeStatus(
  status: Status,
  referenceTime: DateTime
): StatusSummary {
  let mode = Mode.IDLE;
  if (status.initialisation && status.initialisation.status !== "complete") {
    // Distinguish between initalisation running and stopped.
    mode =
      status.initialisation.status === "active"
        ? Mode.INIT_RUNNING
        : Mode.INIT_INACTIVE;
  } else if (status.log?.logging) {
    const loggingOk = logRatesOk(status.log) && strobesOk(status.strobes);
    mode = loggingOk ? Mode.LOGGING_OK : Mode.LOGGING_ERROR;
  }

  // Check whether the message is stale with respect to `referenceTime`. Scanners post
  // their status every 2 minutes, so any message not within 3 minutes of
  // `referenceTime` is stale.
  const stale =
    parseTime(status.timestamp) < referenceTime.minus({ minutes: 3 }) ||
    parseTime(status.timestamp) > referenceTime.plus({ minutes: 3 });
  return { mode, stale };
}

export function colourForMode(mode: Mode): string {
  // CSS colour to use for plots.
  return {
    [Mode.INIT_RUNNING]: "blue",
    [Mode.INIT_INACTIVE]: "orange",
    [Mode.LOGGING_OK]: "green",
    [Mode.LOGGING_ERROR]: "mediumvioletred",
    [Mode.IDLE]: "black",
  }[mode];
}

export function variantForSummary(summary: StatusSummary, useStale: boolean) {
  // Bootstrap colour variant for given status. Can choose whether or not
  // to respect the `stale` information.
  if (useStale && summary.stale) {
    return "secondary";
  }
  return {
    [Mode.INIT_RUNNING]: "info",
    [Mode.INIT_INACTIVE]: "warning",
    [Mode.LOGGING_OK]: "success",
    [Mode.LOGGING_ERROR]: "danger",
    [Mode.IDLE]: "primary",
  }[summary.mode];
}

export function textForMode(mode: Mode): string {
  // Textual description of the mode
  return {
    [Mode.INIT_RUNNING]: "initialising",
    [Mode.INIT_INACTIVE]: "initialisation inactive",
    [Mode.LOGGING_OK]: "logging",
    [Mode.LOGGING_ERROR]: "logging (errors)",
    [Mode.IDLE]: "idle",
  }[mode];
}

// Converts start/end timerange into a firebase query filter.
export function timeRangeFilter(start: DateTime | null, end: DateTime | null) {
  let filter = [];
  if (start) {
    filter.push(...["timestamp", ">=", start.toJSDate()]);
  }
  if (end) {
    filter.push(...["timestamp", "<=", end.toJSDate()]);
  }
  return filter.length > 0 ? filter : undefined;
}

export function statusToGeoJSON(history: Status[]) {
  // Converts array of Status messages to GeoJSON.
  return {
    type: "FeatureCollection",
    features: history
      .map((s, index) => {
        if (s.position === undefined) return null;
        return {
          type: "Feature",
          id: index,
          geometry: {
            type: s.position.length > 1 ? "LineString" : "MultiPoint",
            coordinates: s.position.map((s) => [s.longitude, s.latitude]),
          },
          properties: {
            color: colourForMode(summarizeStatus(s, DateTime.now()).mode),
            ...displayStatus(s),
            // Timestamp in milliseconds is used for mapbox styling.
            milliseconds: s.timestamp.toMillis(),
          },
        };
      })
      .filter((e) => e != null),
  };
}

// Memoize date, using value equality instead of reference equality.
export function useMemoDate(value: DateTime | null) {
  const ref = useRef<DateTime | null>(value);
  // This value flips between true and false every time the value is updated. It is used
  // as the trigger for the final `useMemo`, so when this flips or flops the output is
  // updated.
  const flipflop = useRef<boolean>(false);

  // This is a bit ugly because it needs to deal with one or both of the dates being
  // null.
  if (
    !(value !== null && ref.current !== null && value.equals(ref.current)) &&
    !(value === ref.current)
  ) {
    ref.current = value;
    flipflop.current = !flipflop.current;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => ref.current, [flipflop.current]);
}
