import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import Alert from "react-bootstrap/Alert";

import Auth from "../../Auth";
import { useCollection } from "../../useFirebase";
import TimeRangeSelector from "../../components/TimeRangeSelector";
import { Status } from "./types";
import { timeRangeFilter } from "./utils";
import { makePlotData, makePlotLayout } from "./plot_utils";

// Use a partial plotly bundle to reduce load times.
// (https://github.com/plotly/plotly.js/blob/master/dist/README.md)
import Plotly from "plotly.js-basic-dist";
// Workaround for using plotly-react with a partial bundle.
// (https://github.com/plotly/react-plotly.js/#customizing-the-plotlyjs-bundle)
import createPlotlyComponent from "react-plotly.js/factory";
const Plot = createPlotlyComponent(Plotly);

interface TimeSeriesPlotProps {
  history: Status[];
  start: DateTime | null;
  end: DateTime | null;
}

function TimeSeriesPlot({ history, start, end }: TimeSeriesPlotProps) {
  const data = useMemo(() => makePlotData(history), [history]);
  const layout = useMemo(() => makePlotLayout(start, end), [start, end]);

  if (history.length === 0) {
    return (
      <Alert variant="info">
        No data available from the selected time range
      </Alert>
    );
  }

  return (
    <>
      <p>
        Click and drag to zoom in, use shift+click and drag to pan, and double
        click to reset zoom.
      </p>
      <Plot
        data={data}
        layout={layout}
        config={{ displayModeBar: false, responsive: true }}
        className="w-100"
      />
    </>
  );
}

interface RouteParams {
  scannerId: string;
}

export default function TimeSeries() {
  const { user } = useContext(Auth);
  const { scannerId } = useParams<RouteParams>();

  // Time range for the firebase query.
  const [start, setStart] = useState<DateTime | null>(
    DateTime.now().startOf("day")
  );
  const [end, setEnd] = useState<DateTime | null>(null);

  const filter = useMemo(() => timeRangeFilter(start, end), [start, end]);
  const scannerHistory = useCollection<Status>(
    `scanner-status/${scannerId}/history`,
    {
      operators: user!.admin ? undefined : user!.operators,
      filter,
    }
  );

  return (
    <>
      <h2>{scannerId}</h2>
      <TimeRangeSelector
        start={start}
        end={end}
        setStart={setStart}
        setEnd={setEnd}
      />
      <div className="mt-3">
        <TimeSeriesPlot history={scannerHistory} start={start} end={end} />
      </div>
    </>
  );
}
