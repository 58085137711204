import { useContext } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import { useDocument } from "../../useFirebase";
import { CalibrationQA, QAState } from "./types";
import RelationshipPlot from "./RelationshipPlot";
import { getRelationshipName, urlForResult } from "./utils";
import firebase from "../../firebase";
import Auth, { User } from "../../Auth";

async function setQAState(id: string, state: QAState, user?: User) {
  if (typeof user === "undefined") {
    console.error("Can't set QA state, not logged in");
    return;
  }
  const db = firebase.firestore();
  await db
    .collection("calibration-approval")
    .doc(id)
    .set(
      {
        state,
        modifiedBy:
          state === "new"
            ? firebase.firestore.FieldValue.delete()
            : user.displayName,
      },
      { merge: true }
    );
}

interface SetStateButtonProps {
  id: string;
  state: QAState;
  onStateChange: () => void;
}

function SetStateButton({ id, state, onStateChange }: SetStateButtonProps) {
  const { user } = useContext(Auth);
  const handleClick = () => {
    setQAState(id, state, user);
    onStateChange();
  };

  return (
    <Button size="sm" className="ms-2" variant="primary" onClick={handleClick}>
      {state}
    </Button>
  );
}

interface QACardProps {
  id: string;
  onStateChange: () => void;
}

export default function QACard({ id, onStateChange }: QACardProps) {
  const doc = useDocument<CalibrationQA>(`calibration-approval/${id}`);
  if (doc === null || typeof doc.content === "undefined") {
    return null;
  }
  const { hostname, resultId, relationship } = doc.content;
  const name = getRelationshipName(relationship.xLabel, relationship.yLabel);
  const url = urlForResult(hostname, resultId);

  const states: QAState[] = ["new", "follow-up", "complete"];
  const buttons = states
    .filter((s) => s !== doc.state)
    .map((s) => (
      <SetStateButton key={s} id={id} state={s} onStateChange={onStateChange} />
    ));

  return (
    <Card className="sticky-xl-top-pad" style={{ maxWidth: 500 }}>
      <Card.Header>
        <div className="d-flex align-items-baseline">
          <div className="me-auto">
            <span className="text-capitalize">{name}</span> -{" "}
            <a href={url}>{hostname}</a>
          </div>
          {buttons}
        </div>
      </Card.Header>
      <Card.Body>
        <RelationshipPlot calName="cal" relationship={relationship} />
      </Card.Body>
    </Card>
  );
}
