import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";

import { useCollection, parseTime } from "../../useFirebase";
import { BackupDrive } from "./types";
import { downloadLogBackupsCSV } from "./utils";

interface SearchResultsProps {
  search: string;
}
function SearchResults({ search }: SearchResultsProps) {
  const filter = useMemo(() => ["logs", "array-contains", search], [search]);
  const results = useCollection<BackupDrive>("log-backups", { filter });

  if (search.length === 0) {
    return null;
  }

  return (
    <div className="pt-3">
      <h5>Backup locations for "{search}"</h5>
      {results.length === 0 && <h5>No results</h5>}
      {results.length > 0 && (
        <Table size="sm" className="pt-4" style={{ maxWidth: 900 }}>
          <thead>
            <tr>
              <th>Hostname</th>
              <th>Disk</th>
              <th>Mountpoint</th>
              <th>Updated</th>
            </tr>
          </thead>
          <tbody>
            {results.map((r) => (
              <tr key={r.id}>
                <td>{r.hostname}</td>
                <td>{r.disk}</td>
                <td>{r.mount}</td>
                <td>{parseTime(r.when).toRelative()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}

function LogSearch() {
  // Track the search input text separately from the actual search string.
  // On submit, we copy `searchInput` into `search`.
  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setSearchInput(searchInput.trim());
    setSearch(searchInput.trim());
    e.preventDefault();
  };

  return (
    <>
      <h4>Search for a specific log:</h4>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} sm={5} className="me-sm-2 mb-2">
            <Form.Control
              placeholder="log name"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <Form.Text>
              NOTE: Partial name match is <em>not</em> supported
            </Form.Text>
          </Form.Group>
          <Col sm={2}>
            <Button className="mb-sm-2" type="submit">
              Search
            </Button>
          </Col>
        </Row>
      </Form>
      <SearchResults search={search} />
    </>
  );
}

function DownloadButton() {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = useCallback(async () => {
    setDownloading(true);
    console.log("downloading!");
    await new Promise((resolve) => setTimeout(resolve, 4000));
    setDownloading(false);
  }, []);

  useEffect(() => {
    // When "downloading" toggles to true, we initiate the download.
    if (downloading) {
      return downloadLogBackupsCSV(() => setDownloading(false));
    }
  }, [downloading]);

  return (
    <Button
      className="mt-2 mb-4"
      variant="outline-primary"
      onClick={handleDownload}
      disabled={downloading}
    >
      {downloading ? "Downloading..." : "Download as CSV"}
    </Button>
  );
}

export default function LogBackups() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <div>
          [ <Link to="/">Home</Link> ]
        </div>
        <h2>Log backups</h2>
        <div>
          <DownloadButton />
        </div>
        <LogSearch />
      </Route>
    </Switch>
  );
}
