import { ReactNode, useEffect, useState } from "react";

interface PeriodicRenderProps {
  seconds?: number;
  children: () => ReactNode;
}

// Periodically re-render child components.
export default function PeriodicRender({
  children,
  seconds = 10,
}: PeriodicRenderProps) {
  // Flip/flop the state to trigger re-renders.
  const [, setTick] = useState(false);
  useEffect(() => {
    const interval = setInterval(
      () => setTick((tick) => !tick),
      seconds * 1000
    );
    return () => clearInterval(interval);
  }, [seconds]);

  return <>{children()}</>;
}
