import ListGroup from "react-bootstrap/ListGroup";

interface ErrorFallbackProps {
  title: string;
}

export default function ErrorFallback({ title }: ErrorFallbackProps) {
  return (
    <ListGroup.Item className="d-flex">
      <strong>{title}</strong>
      <div className="ms-auto text-danger">error: unable to display data</div>
    </ListGroup.Item>
  );
}
