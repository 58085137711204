import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";

import firebase from "../firebase";

// Hook to deal with processing of the firebase login token.
//
// To log into firebase, the user first logs into fusionauth, which sets a httpOnly
// cookie. To go from this cookie to a custom token that can log the user into firebase,
// we post to "/auth/token" on the backend, which verifies we area logged in and
// generates to the token.
//
// On initial page load, this hook starts in the "loading" state. It initiates the POST
// to "/auth/token", and if successful uses the returned token to sign into firebase and
// transitions to the "ok" state. If the POST returns a 401 error, the user must not be
// logged in, so this hook transitions to the "login-required" state. If the POST fails
// for any other reason, the hook transitions to the "error" state.

type TokenState = "loading" | "ok" | "login-required" | "error";
function useToken(): TokenState {
  const [tokenState, setTokenState] = useState<TokenState>("loading");

  useEffect(() => {
    async function getToken() {
      const resp = await fetch("/auth/token", { method: "POST" });
      if (resp.ok) {
        const data = await resp.json();
        try {
          await firebase.auth().signInWithCustomToken(data["token"]);
          setTokenState("ok");
        } catch (error) {
          console.error("firebase signin failed: ", error);
          setTokenState("error");
        }
      } else if (resp.status === 401) {
        setTokenState("login-required");
        // Small delay before redirect so user can read message.
        setTimeout(() => {
          window.location.href = "/auth/login";
        }, 600);
      } else {
        console.error("failed to fetch token: ", resp);
        setTokenState("error");
      }
    }
    getToken();
  }, []);
  return tokenState;
}

// This page conversion of the fusionauth login cookie into a firebase login token. See
// comments on `useToken` for more information. This component just displays appropriate
// UI depending on the state returned by `useToken`.
export default function Login() {
  const tokenState = useToken();

  if (tokenState === "ok") {
    return <Redirect to="/" />;
  } else if (tokenState === "login-required") {
    return (
      <div>
        <p>Login required, redirecting...</p>
        <p>
          If you are not automatically redirected,{" "}
          <a href="/auth/login">click here</a>.
        </p>
      </div>
    );
  } else if (tokenState === "error") {
    return (
      <div>
        Authentication error: please <a href="/auth/login">retry</a> or contact
        Green Atlas.
      </div>
    );
  } else {
    // tokenState === 'loading'
    return <div>Loading...</div>;
  }
}
