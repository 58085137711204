import firebase from "../../firebase";

import { parseTime } from "../../useFirebase";
import { BackupDrive } from "./types";

function drivesToCSV(drives: BackupDrive[]): string {
  const header = ["log", "hostname", "disk", "mount", "updated"].join(",");
  const rows = [header].concat(
    drives.flatMap((d) =>
      d.logs.map((l) =>
        [l, d.hostname, d.disk, d.mount, parseTime(d.when).toISO()].join(",")
      )
    )
  );
  return rows.join("\n");
}

export function downloadLogBackupsCSV(completion_cb: () => void) {
  // Load all drives from firebase and download as CSV by opening
  // new window. Calls `completion_cb` when done.
  //
  // It was easier to implement this directly instead of
  // trying to use `useCollection` inside a `useEffect`.
  const query = firebase.firestore().collection("log-backups");
  return query.onSnapshot(
    (snapshot: firebase.firestore.QuerySnapshot) => {
      let drives = snapshot.docs.map(
        (doc: any) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as BackupDrive)
      );
      console.log(`downloading data for ${drives.length} drives`);
      const blob = new Blob([drivesToCSV(drives)], { type: "text/csv" });
      const fileURL = URL.createObjectURL(blob);
      // Create a link element and trigger the download.
      const link = document.createElement("a");
      link.download = "logs.csv";
      link.href = fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      completion_cb();
    },
    (error: firebase.firestore.FirestoreError) => {
      console.log(error);
      completion_cb();
    }
  );
}
