import { CalibrationUnits } from "./types";

export function urlForResult(hostname: string, resultId: string): string {
  const pId = hostname.split("-")[1];
  const url = `http://p${pId}.greenatl.as/calibration/results/${resultId}`;
  return url;
}

export function getRelationshipName(xLabel: string, ylabel: string) {
  const table: Record<string, string> = {
    "inputs/counts/count/mean -> outputs/heatmap/total/mean": "count",
    "inputs/sizes/size/mean -> outputs/heatmap/size/mean": "size",
    "inputs/weights/weight/mean -> outputs/heatmap/total/mean": "weight",
  };
  const key = `${xLabel} -> ${ylabel}`;
  return table[key] || key;
}

const NAME_MAP: Record<string, string> = {
  "inputs/counts/count/mean": "field-count",
  "inputs/sizes/size/mean": "field-size",
  "inputs/weights/weight/mean": "field-weight",
  "outputs/heatmap/size/mean": "size",
  "outputs/heatmap/total/mean": "detections",
  "outputs/geometry/height/mean": "height",
  "outputs/geometry/density/mean": "density",
  "outputs/geometry/area/mean": "area",
  "outputs/geometry/leaf-area/mean": "leaf-area",
};

export function getFriendlyName(measure_name: string) {
  return NAME_MAP[measure_name] || measure_name;
}

export function getUnits(units?: CalibrationUnits): string | null {
  if (typeof units === "undefined") {
    return null;
  }
  const { numerator, denominator, suffix } = units;
  // Build string of the form "numerator / denominator (suffix)"
  // e.g. "count / m^2" or "mm (calibrated)"
  const parts = [numerator];
  if (typeof denominator !== "undefined") {
    if (parts.length === 0) {
      parts.push("1");
    }
    parts.push("/", denominator);
  }
  if (typeof suffix !== "undefined") {
    parts.push(`(${suffix})`);
  }
  return parts.join(" ");
}
