import { useContext, useEffect, ReactNode, useState } from "react";
import { createPortal } from "react-dom";
import { MapContext } from "react-mapbox-gl";
import { IControl } from "mapbox-gl";

class MapElement implements IControl {
  _container: HTMLElement | null = null;

  onAdd() {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl";
    return this._container;
  }

  onRemove() {
    this._container?.remove();
    this._container = null;
  }

  get container() {
    return this._container;
  }
}

interface MapControlProps {
  // If true, render element without the `mapboxgl-ctrl-group` styling.
  plain?: boolean;
  // Position of the control.
  position?: "top-left" | "top-right" | "bottom-left" | "bottom-right";
  // The control(s) to render.
  children: ReactNode;
}

export default function MapControl({
  plain,
  children,
  position = "top-right",
}: MapControlProps) {
  const map = useContext(MapContext);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  // Attach the control to the map, and remove it on unmount.
  useEffect(() => {
    if (!map) return () => {};
    const control = new MapElement();
    map.addControl(control, position);
    setContainer(control.container);
    return () => {
      setContainer(null);
      map.removeControl(control);
    };
  }, [map, position]);

  // Render react children into the control's container.
  return (
    container &&
    createPortal(
      plain ? children : <div className="mapboxgl-ctrl-group">{children}</div>,
      container
    )
  );
}
