import Table from "react-bootstrap/Table";
import { CheckLg as OkIcon, XLg as ErrorIcon } from "react-bootstrap-icons";

import { LogRate } from "../../types";

interface RateTableProps {
  rates: Record<string, LogRate>;
}

export default function RateTable({ rates }: RateTableProps) {
  return (
    <Table size="sm" style={{ lineHeight: 1 }}>
      <thead>
        <tr>
          <th>sensor</th>
          <th>size</th>
          <th>rate</th>
          <th>status</th>
        </tr>
      </thead>
      <tbody>
        {Object.entries(rates).map(([name, rate]) => (
          <RateRow key={name} name={name} rate={rate} />
        ))}
      </tbody>
    </Table>
  );
}

interface RateRowProps {
  name: string;
  rate: LogRate;
}

function RateRow({ name, rate }: RateRowProps) {
  const symbol = rate.above_threshold ? <OkIcon /> : <ErrorIcon />;
  const rowClass = rate.above_threshold ? "" : "table-danger";

  const fpsOrRate = rate.fps === "-" ? rate.rate : `${rate.fps}fps`;

  return (
    <tr className={rowClass}>
      <td>{name}</td>
      <td>{rate.size}</td>
      <td>{fpsOrRate}</td>
      <td className="text-center">{symbol}</td>
    </tr>
  );
}
