import { Popup } from "react-mapbox-gl";
import { DateTime } from "luxon";
import ListGroup from "react-bootstrap/ListGroup";

import { Status } from "../../types";
import { parseTime, getPosition, summarizeStatus } from "../../utils";

import LogStatus from "./LogStatus";
import InitStatus from "./InitStatus";
import StatusDetail from "./StatusDetail";
import StrobeStatus from "./StrobeStatus";
import DiskStatus from "./DiskStatus";
import TrinketStatus from "./TrinketStatus";
import Header from "./Header";
import PeriodicRender from "../../../../components/PeriodicRender";

interface ScannerPopupProps {
  status: Status;
  referenceTime: DateTime | null;
}

export default function ScannerPopup({
  status,
  referenceTime,
}: ScannerPopupProps) {
  return (
    <Popup
      className="scanner-popup"
      key={`popup-${status.id}`}
      coordinates={getPosition(status, "latest")}
      style={{ padding: 0, width: 320 }}
    >
      <PeriodicRender>
        {() => {
          const statusSummary = summarizeStatus(
            status,
            referenceTime ?? DateTime.now()
          );
          return (
            <ListGroup variant="flush">
              <Header
                hostname={status.hostname}
                timestamp={parseTime(status.timestamp)}
                summary={statusSummary}
              />
              <InitStatus
                init={status.initialisation}
                mode={statusSummary.mode}
              />
              <LogStatus log={status.log} />
              {status.strobes && <StrobeStatus strobes={status.strobes} />}
              {status.disk && <DiskStatus disk={status.disk} />}
              {status.trinket && <TrinketStatus trinket={status.trinket} />}
              <StatusDetail status={status} />
            </ListGroup>
          );
        }}
      </PeriodicRender>
    </Popup>
  );
}
