import ListGroup from "react-bootstrap/ListGroup";
import { withErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./ErrorFallback";
import { Log } from "../../types";
import RateTable from "./RateTable";

interface LogStatusProps {
  log: Log;
}

function LogStatus({ log }: LogStatusProps) {
  if (!log.logging) {
    return null;
  }

  return (
    <ListGroup.Item>
      <div className="d-flex">
        <strong>Logging</strong>
        <div className="ms-auto">{log.name}</div>
      </div>
      {log.rate ? (
        <RateTable rates={log.rate} />
      ) : (
        <p>Computing log rates&hellip;</p>
      )}
    </ListGroup.Item>
  );
}

export default withErrorBoundary(LogStatus, {
  fallback: <ErrorFallback title="Logging" />,
});
