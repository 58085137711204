import ListGroup from "react-bootstrap/ListGroup";
import { withErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./ErrorFallback";
import { Trinket } from "../../types";

interface TrinketStatusProps {
  trinket: Trinket;
}

function TrinketStatus({ trinket }: TrinketStatusProps) {
  const { current_centre, voltage } = trinket;
  return (
    <ListGroup.Item>
      <div className="d-flex">
        <strong>Power</strong>
        <div className="ms-auto">
          {voltage} V, {current_centre} A
        </div>
      </div>
    </ListGroup.Item>
  );
}

export default withErrorBoundary(TrinketStatus, {
  fallback: <ErrorFallback title="Power" />,
});
