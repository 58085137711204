import { DateTime } from "luxon";
import { CaretRightFill } from "react-bootstrap-icons";
import { Marker } from "react-mapbox-gl";

import { Status } from "../types";
import {
  getPosition,
  shortId,
  summarizeStatus,
  variantForSummary,
} from "../utils";

interface ScannerMarkerProps {
  status: Status;
  referenceTime: DateTime;
  onClick: () => void;
}
export default function ScannerMarker({
  status,
  referenceTime,
  onClick,
}: ScannerMarkerProps) {
  const variant = variantForSummary(
    summarizeStatus(status, referenceTime),
    true
  );
  return (
    <Marker
      coordinates={getPosition(status, "latest")}
      anchor="right"
      onClick={onClick}
    >
      <div className="scanner-marker">
        <div className={`scanner-number bg-${variant}`}>
          {shortId(status.hostname)}
        </div>
        <div className={`scanner-icon text-${variant}`}>
          <CaretRightFill />
        </div>
      </div>
    </Marker>
  );
}
