import React from "react";
import { DateTime } from "luxon";
import Button from "react-bootstrap/Button";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";

export interface TimeRangeSelectorProps {
  start: DateTime | null;
  end: DateTime | null;
  setStart: (time: DateTime | null) => void;
  setEnd: (time: DateTime | null) => void;
}

type PickerDateTime = [Date?, Date?] | null;

// Convert PickerDateTime format to [start, end] pair.
function pickerToState(
  picker: PickerDateTime
): [DateTime | null, DateTime | null] {
  if (!picker) {
    return [null, null];
  }
  const [s, e] = picker;
  return [s ? DateTime.fromJSDate(s) : null, e ? DateTime.fromJSDate(e) : null];
}

// Convert [start, end] pair to PickerDateTime.
function stateToPicker(
  start: DateTime | null,
  end: DateTime | null
): PickerDateTime {
  return [start?.toJSDate(), end?.toJSDate()];
}

const PRESETS = ["today", "yesterday", "last 7 days", "last 30 days"] as const;
type Preset = typeof PRESETS[number];

export default function TimeRangeSelector({
  start,
  end,
  setStart,
  setEnd,
}: TimeRangeSelectorProps) {
  const handleChange = (update: PickerDateTime) => {
    const [start, end] = pickerToState(update);
    setStart(start);
    setEnd(end);
  };

  const handlePreset = (preset: Preset) => {
    const todayStart = DateTime.now().startOf("day");
    const yesterdayStart = todayStart.plus({ days: -1 });
    switch (preset) {
      case "today":
        setStart(todayStart);
        setEnd(null);
        break;
      case "yesterday":
        setStart(yesterdayStart);
        setEnd(yesterdayStart.endOf("day"));
        break;
      case "last 7 days":
        setStart(todayStart.plus({ days: -7 }));
        setEnd(null);
        break;
      case "last 30 days":
        setStart(todayStart.plus({ days: -30 }));
        setEnd(null);
        break;
    }
  };

  // NOTE: error in DateTimeRangePicker types means calendarIcon has to be cast
  // to something other than null.
  return (
    <div className="d-flex flex-row flex-wrap">
      <DateTimeRangePicker
        onChange={handleChange}
        value={stateToPicker(start, end)}
        disableClock={true}
        calendarIcon={null as any}
        format="y-MM-dd H:mm:ss"
        className="my-1 me-2"
        showFixedNumberOfWeeks={true}
      />
      <div className="d-flex ms-n1 flex-wrap">
        {PRESETS.map((p) => (
          <Button
            size="sm"
            key={p}
            className="my-1 ms-1"
            onClick={() => handlePreset(p)}
          >
            {p}
          </Button>
        ))}
      </div>
    </div>
  );
}
