import { useMemo } from "react";
// Use a partial plotly bundle to reduce load times.
// (https://github.com/plotly/plotly.js/blob/master/dist/README.md)
import Plotly from "plotly.js-basic-dist";
// Workaround for using plotly-react with a partial bundle.
// (https://github.com/plotly/react-plotly.js/#customizing-the-plotlyjs-bundle)
import createPlotlyComponent from "react-plotly.js/factory";

import { CalibrationRelationship } from "./types";

import { getFriendlyName, getUnits } from "./utils";

const Plot = createPlotlyComponent(Plotly);

export interface RelationshipPlotProps {
  calName: string;
  relationship: CalibrationRelationship;
}

export default function RelationshipPlot({
  calName,
  relationship,
}: RelationshipPlotProps) {
  const { gradient, gradientStddev, points, xLabel, yLabel, xUnits, yUnits } =
    relationship;
  const maxX = Math.max(...points.map((p) => p.x));
  const maxY = Math.max(...points.map((p) => p.y));
  const axisXLim = maxX * 1.1;
  const axisYLim = maxY * 1.1;

  const xLabelStr = getFriendlyName(xLabel);
  const yLabelStr = getFriendlyName(yLabel);
  const xUnitsStr = getUnits(xUnits);
  const yUnitsStr = getUnits(yUnits);

  const data = useMemo(() => {
    let items: Partial<Plotly.PlotData>[] = [];

    items.push({
      x: [0, axisXLim],
      y: [0, gradient * axisXLim],
      type: "scatter",
      mode: "lines",
      name: "Calibration Factor",
      hoverinfo: "none",
    });

    if (typeof gradientStddev !== "undefined") {
      items.push({
        x: [0, axisXLim],
        y: [0, (gradient + 2 * gradientStddev) * axisXLim],
        type: "scatter",
        mode: "lines",
        line: {
          dash: "dot",
          color: "green",
        },
        name: "Calibration Factor ± 2 SD",
        hoverinfo: "none",
      });
      items.push({
        x: [0, axisXLim],
        y: [0, (gradient - 2 * gradientStddev) * axisXLim],
        type: "scatter",
        mode: "lines",
        line: {
          dash: "dot",
          color: "green",
        },
        name: "Calibration Factor - 2 SD",
        showlegend: false,
        hoverinfo: "none",
      });
    }

    items.push({
      x: points.map((p) => p.x),
      y: points.map((p) => p.y),
      text: points.map((p) => p.label),
      type: "scatter",
      mode: "text+markers",
      textposition: "bottom center",
      name: "Field Measurements",
      hoverinfo: "x+y",
    });

    return items;
  }, [gradient, gradientStddev, axisXLim, points]);

  return (
    <Plot
      data={data}
      layout={{
        xaxis: {
          range: [0, axisXLim],
          title: {
            text: `${xLabelStr}${xUnitsStr ? " - " + xUnitsStr : ""}`,
          },
        },
        yaxis: {
          range: [0, axisYLim],
          title: {
            text: `${yLabelStr}${yUnitsStr ? " - " + yUnitsStr : ""}`,
          },
        },
        margin: {
          t: 30,
          b: 40,
          l: 50,
          r: 30,
        },
        legend: {
          x: 0.05,
          y: 1.0,
          bgcolor: "#fff9",
        },
      }}
      config={{
        responsive: true,
        // Remove all modebar (the menu at the top left of the plot) buttons, except
        // save-to-image button.
        modeBarButtonsToRemove: [
          "zoom2d",
          "pan2d",
          "select2d",
          "lasso2d",
          "zoomIn2d",
          "zoomOut2d",
          "autoScale2d",
          "resetScale2d",
        ],
        displaylogo: false, // Don't display plotly logo.
        toImageButtonOptions: {
          filename: `Calibration ${calName} - ${yLabelStr} vs ${xLabelStr}`,
          scale: 4, // Scale up plot size 4x, to get a high resolution image.
        },
      }}
      style={{ width: "100%", minHeight: 300 }}
    />
  );
}
