import { DateTime } from "luxon";
import { useMemo } from "react";

import { User } from "../../../Auth";
import { useCollection } from "../../../useFirebase";
import { Status } from "../types";
import { parseTime, timeRangeFilter, useMemoDate } from "../utils";

export function useHistory(
  scannerId: string,
  start: DateTime | null,
  end: DateTime | null,
  user: User
): Status[] {
  // "start" and "end" are used to filter the trajectory segment being displayed. To
  // prevent spamming firebase with requests every time the filters are changed, we
  // first clamp "start" and "end" to the nearest day and query whole day(s) of data
  // from firebase.
  //
  // The data from firebase is then further filtered down to just the requested
  // interval, meaning you can make second-by-second adjustments to the display without
  // incurring any network usage.

  // useMemoData does a deep equality, so "dayStart" only updates when the actual value
  // changes, not when the reference updates.
  const dayStart = useMemoDate(start ? start.startOf("day") : null);
  const dayEnd = useMemoDate(end ? end.endOf("day") : null);
  // Construct a firebase filter based on whole day(s).
  const dayFilter = useMemo(
    () => timeRangeFilter(dayStart, dayEnd),
    [dayStart, dayEnd]
  );

  // Load firebase data for whole day(s).
  const dayHistory = useCollection<Status>(
    `scanner-status/${scannerId}/history`,
    {
      operators: user.admin ? undefined : user.operators,
      filter: dayFilter,
    }
  );

  // Filter history down to actual requested start/end.
  const history = useMemo(
    () =>
      dayHistory.filter(({ timestamp }) => {
        const t = parseTime(timestamp);
        return (start === null || t >= start) && (end === null || t <= end);
      }),
    [start, end, dayHistory]
  );

  return history;
}
